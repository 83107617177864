import { MaterialIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import { useIsFocused } from "@react-navigation/native";
import { Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  FlatList,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";
import { ApButton, ApSafeAreaView } from "../components/v1";
import {
  ApLangDropdown,
  ApPasswordInput,
  ApTextInput,
} from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { ERROR_MSG } from "../constants/errors";
import { LANGUAGES } from "../constants/master";
import { useProfileState } from "../context";
import { useAuthState } from "../context/auth";
import { ApErrorToast } from "../services";
import { svg } from "../svg";
import { COLORS } from "../constants/theme";

const FormSchema = Yup.object().shape({
  account_name: Yup.string()
    .email(ERROR_MSG.InvalidEmail)
    .required(ERROR_MSG.EmailRequired),
  password: Yup.string().required(ERROR_MSG.PasswordRequired),
});

const SignIn: React.FC = () => {
  const navigation = useNavigation();
  const { login } = useAuthState();
  const { setLang } = useProfileState();
  const formRef = useRef<FormikProps<any>>();
  const isFocused = useIsFocused();
  const [emailOptions, setEmailOptions] = useState([]);
  const [showEmailOptions, setShowEmailOptions] = useState(false);

  const handleSignin = async (values: any) => {
    login(values)
      .then(async (rs) => {
        AsyncStorage.getItem("language").then((rs) => {
          if (rs === null) {
            setLang(LANGUAGES[0].languageCode).then((rs) => {
              navigation.navigate("Home" as never);
              formRef.current?.resetForm();
            });
          } else {
            setLang(LANGUAGES.filter((e) => e.id === rs)[0].languageCode).then(
              (rs) => {
                navigation.navigate("Home" as never);
                formRef.current?.resetForm();
              }
            );
          }
        });

        var email = formRef.current.values?.account_name;
        if (email.trim() === "") {
          return;
        }

        if (emailOptions.includes(email)) {
          return;
        }

        const deviceID = await getDeviceID();
        const storedEmails = await AsyncStorage.getItem("emails");
        const emails = storedEmails ? JSON.parse(storedEmails) : [];
        const updatedEmails = [...emails, { email, deviceID }];
        await AsyncStorage.setItem("emails", JSON.stringify(updatedEmails));
        console.log("Email stored successfully");
      })
      .catch((err) => {
        ApErrorToast("Login Failed", err);
      });
    // AsyncStorage.getItem("language").then(rs => {
    //   if(rs === null)
    //   {
    //     login(values)
    //     .then((rs) => {
    //       setLang(LANGUAGES[0].languageCode).then((rs) => {
    //         navigation.navigate("Home" as never);
    //         formRef.current?.resetForm();
    //       });
    //     })
    //     .catch((err) => {
    //       ApErrorToast("Login Failed", err);
    //     });
    //   }
    // //   else{
    // //       login(values)
    // //       .then((rs) => {
    //         setLang(LANGUAGES.filter(e => e.id === rs)[0].languageCode).then((rs) => {
    //           navigation.navigate("Home" as never);
    //           formRef.current?.resetForm();
    //         });
    //       })
    //       .catch((err) => {
    //         ApErrorToast("Login Failed", err);
    //       });
    //   }
    // })
  };

  useEffect(() => {
    retrieveEmails();
  }, []);

  const getDeviceID = async () => {
    const { userAgent } = navigator;
    return `web_${userAgent}`;
  };

  const retrieveEmails = async () => {
    const deviceID = await getDeviceID();
    const storedEmails = await AsyncStorage.getItem("emails");
    const emails = storedEmails ? JSON.parse(storedEmails) : [];
    const filteredEmails = emails.filter((item) => item.deviceID === deviceID);
    setEmailOptions(filteredEmails.map((item) => item.email));
  };

  const renderEmailOption = ({ item }) => (
    <View style={styles.buttonContainer}>
      <TouchableOpacity
        style={styles.button}
        onPress={() => OptionSetEmail(item)}
      >
        <Text style={styles.buttonText}>{item}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.deleteButton}
        onPress={() => deleteEmail(item)}
      >
        <MaterialIcons name="clear" size={20} color="black" />
      </TouchableOpacity>
    </View>
  );

  const deleteEmail = async (item) => {
    const storedEmails = await AsyncStorage.getItem("emails");
    const emails = storedEmails ? JSON.parse(storedEmails) : [];
    const updatedEmails = emails.filter((storage) => storage.email !== item);
    await AsyncStorage.setItem("emails", JSON.stringify(updatedEmails));
    retrieveEmails(); // Refresh the email options list
  };

  const OptionSetEmail = (item) => {
    formRef.current.setFieldValue("account_name", item);
    setShowEmailOptions(false);
  };

  const renderContent = () => {
    return (
      <ImageBackground
        style={{
          width: theme.SIZES.width,
          height: theme.SIZES.height,
          justifyContent: "flex-end",
        }}
        source={require("../../assets/bg-05.png")}
        resizeMode="cover"
      >
        <View style={{ paddingHorizontal: 35, paddingBottom: 43 }}>
          <ApText
            style={{
              fontSize: 22,
              color: "white",
              fontWeight: "bold",
              paddingBottom: 5,
            }}
            translateKey="Hello"
          />
          <ApText
            style={{ fontSize: 22, color: "white", fontWeight: "bold" }}
            translateKey="Welcome"
          />
        </View>
        <View
          style={{
            backgroundColor: "white",
            paddingTop: theme.SIZES.height * 0.05,
            paddingBottom: theme.SIZES.height * 0.07,
            paddingHorizontal: 20,
            borderTopLeftRadius: 35,
            borderTopRightRadius: 35,
            height: 500,
          }}
        >
          <View
            style={{
              zIndex: 999,
              alignSelf: "flex-start",
              position: "absolute",
              top: 20,
              right: 20,
            }}
          >
            <ApLangDropdown />
          </View>

          <View style={{ marginTop: 10 }}>
            <Formik
              innerRef={formRef as any}
              autoComplete="off"
              //validationSchema={FormSchema}
              initialValues={{ account_name: "", password: "" }}
              onSubmit={handleSignin}
            >
              {(props: FormikProps<any>) => (
                <KeyboardAwareScrollView
                  contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
                >
                  <View>
                    <ApTextInput
                      name="account_name"
                      label="Email"
                      labelTranslateKey="Email"
                      placeholder="mail@example.com"
                      placeHolderTranslateKey="EnterEmailAddress"
                      onChange={() => {
                        setShowEmailOptions(true);
                      }}
                      icon={<svg.CheckSvg />}
                      rightIcon={
                        <TouchableOpacity
                          style={{ marginRight: 10 }}
                          onPress={() =>
                            showEmailOptions
                              ? setShowEmailOptions(false)
                              : setShowEmailOptions(true)
                          }
                        >
                          {showEmailOptions ? (
                            <MaterialIcons
                              name="expand-less"
                              size={20}
                              color="black"
                            />
                          ) : (
                            <MaterialIcons
                              name="expand-more"
                              size={20}
                              color="black"
                            />
                          )}
                        </TouchableOpacity>
                      }
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        paddingBottom: 0,
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    />
                    {showEmailOptions && (
                      <FlatList
                        data={emailOptions}
                        renderItem={renderEmailOption}
                        keyExtractor={(item, index) => index.toString()}
                        style={
                          emailOptions.length >= 3
                            ? { maxHeight: 95 }
                            : undefined
                        }
                      />
                    )}

                    <ApPasswordInput
                      label="Password"
                      name="password"
                      labelTranslateKey="Password"
                    />

                    {/* <ApTextInput
                      label="Password"
                      name="password"
                      labelTranslateKey="Password"
                      placeholder="••••••"
                      secureTextEntry
                      rightIcon={
                        <TouchableOpacity>
                          <svg.EyeOffSvg />
                        </TouchableOpacity>
                      }
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    /> */}

                    <View
                      style={{
                        marginTop: 10,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 30,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate("ForgotPassword" as never)
                        }
                      >
                        <ApText
                          style={{
                            ...theme.FONTS.Mulish_400Regular,
                            fontSize: 13,
                            color: theme.COLORS.grey1,
                            lineHeight: 16 * 1.2,
                          }}
                          translateKey="ForgotPassword"
                        />
                      </TouchableOpacity>
                    </View>

                    <ApButton
                      label="Signin"
                      round="xxl"
                      labelTranslateKey="SignIn"
                      type="primary"
                      onPress={props.handleSubmit}
                    />
                    <ApButton
                      label="Sign up"
                      round="xxl"
                      labelTranslateKey="SignUp"
                      type="light"
                      style={{
                        marginTop: 20,
                      }}
                      onPress={() => navigation.navigate("SignUp" as never)}
                    />
                  </View>
                </KeyboardAwareScrollView>
              )}
            </Formik>
          </View>

          <View 
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingHorizontal: 20,
              marginTop: 20
            }}
          >
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("Policy" as never)
              }
            >
                <ApText
                  color={COLORS.primary}
                  style={{
                    fontSize: 13,
                    borderBottomColor: COLORS.primary,
                    borderBottomWidth: 1
                  }}
                  translateKey="PrivacyPolicy"
                />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() =>
                navigation.navigate("TermsAndConditions" as never)
              }
            >
                <ApText
                  color={COLORS.primary}
                  style={{
                    fontSize: 13,
                    borderBottomColor: COLORS.primary,
                    borderBottomWidth: 1
                  }}
                  translateKey="TermsAndConditions"
                />
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    );
  };

  return (
    <ApSafeAreaView>
      {/* {renderHeader()} */}
      {renderContent()}
    </ApSafeAreaView>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: "#eee",
    padding: 8,
  },
  button: {
    flex: 1,
    alignItems: "flex-start",
  },
  buttonText: {
    color: "black",
    textAlign: "center",
    fontSize: 14,
    fontWeight: "400",
  },
  deleteButton: {
    marginLeft: 10,
  },
});

export default SignIn;
