import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { components } from "../../../components";
import { ApLoader, ApSafeAreaView } from "../../../components/v1";
import { useConfigState, useTradingContext } from "../../../context";
import { useCalcPnl } from "../../../hooks/master";
import {
  AboutStrategy,
  DeleteBotModal,
  DetailsNav,
  ExtraActions,
  FirstInfo,
  NextEntryModal,
  PolicyOperations,
  SecondInfo,
  TerminateBotModal,
} from "./components";

export const BotDetails: React.FC = ({ navigation, route }: any) => {
  const bex_id = route.params.bex_id;
  const { getBotExecutorDetails, loading, market, platform } = useTradingContext();

  const { calcPNLAmount, calcPNLPerc } = useCalcPnl();

  const [showModal, setShowModal] = useState(false);
  const [showTermModal, setShowTermModal] = useState(false);
  const [showNextEntryModal, setShowNextEntryModal] = useState(false);
  const { priceList, getPriceList } = useConfigState();

  useEffect(() => {
    getBotExecutorDetails(bex_id, true);
  }, []);

  useFocusEffect(
    useCallback(() => {
      getPriceList({ from: "bdd", market, platform });
      const interval = setInterval(() => {
        getPriceList({from:"bdd", market, platform});
      }, 8000);
      return () => clearInterval(interval);
    }, [market])
  );


  const renderHeader = () => {
    return (
      <components.Header
        title="Bot Details"
        titleTranlateKey="BotDetails"
        goBack={true}
      />
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading ? (
        <View style={{ paddingTop: 10 }}>
          <ApLoader />
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            paddingTop: 20,
            paddingHorizontal: 20,
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              borderRadius: 10,
            }}
          >
            <FirstInfo />
            <SecondInfo
              calculatePNLPerc={calcPNLPerc}
              calculatePNLAmount={calcPNLAmount}
              priceList={priceList}
            />
            <DetailsNav bex_id={bex_id} />
            <AboutStrategy />
            <PolicyOperations
              setShowNextEntryModal={setShowNextEntryModal}
              bex_id={bex_id}
              setShowTermModal={setShowTermModal}
            />
          </View>

          <ExtraActions bex_id={bex_id} setShowModal={setShowModal} />
        </View>
      )}

      <TerminateBotModal
        setShowTermModal={setShowTermModal}
        showTermModal={showTermModal}
        bex_id={bex_id}
      />

      <NextEntryModal
        setShowNextEntryModal={setShowNextEntryModal}
        showNextEntryModal={showNextEntryModal}
        bex_id={bex_id}
      />

      <DeleteBotModal
        showModal={showModal}
        setShowModal={setShowModal}
        bex_id={bex_id}
      />
    </ApSafeAreaView>
  );
};
