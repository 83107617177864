import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import * as Yup from "yup";
import { components } from "../../components";
import { ApModalAlt } from "../../components/Modal";
import {
  ApButton,
  ApCheckInputAlt,
  ApDropdown,
  ApLoader,
  ApRow,
  ApSafeAreaView,
  ApTextInput,
  ApToggleInput,
} from "../../components/v1";
import { ApText } from "../../components/v1/typography";
import { theme } from "../../constants";
import { ERROR_MSG } from "../../constants/errors";
import { useCopyState } from "../../context/copy";
import { ApSuccessToast } from "../../services";
import { svg } from "../../svg";
import InfoSvg from "../../svg/InfoSvg";
import { MasterData, TRADING_PLATFORMS_OPTIONS } from "../../constants/master";
import { useTradingContext } from "../../context";
const FormSchema = Yup.object().shape({
  agree: Yup.bool().isTrue(ERROR_MSG.AgreeTerms).required(ERROR_MSG.AgreeTerms),
});

const CopyTradeDetails: React.FC = ({ navigation, route }: any) => {
  const copy_trade_id = route?.params?.copy_trade_id;
  // const platform = route?.params?.platform;
  const [formData, setFormData] = useState<any>();
  const { setPlatform, platform } = useTradingContext();
  const [showConfirm, setShowConfirm] = useState<{data?: any, open: boolean}>({
    open: false,
  })
  // console.log(platform);
  const { t } = useTranslation();
  const {
    selectedTrade,
    updateCopyTrade,
    copyTrade,
    getCopyTradeDetail,
    loading,
    initLoading
  } = useCopyState();
  const formRef = useRef<FormikProps<any>>();
  const [modal, setModal] = useState<{
    type?: "confirm" | "hint" | "confirmCloseRelatedPositions";
    show: boolean;
    hintKey?: string;
  }>();

  useEffect(() => {
    getCopyTradeDetail(copy_trade_id);
  }, [copy_trade_id]);

  const renderHeader = () => {
    return (
      <components.Header
        title="Copy Trade Details"
        goBack={true}
        titleTranlateKey="CopyTradeDetails"
        // customRightIcon={
        //   <TouchableOpacity
        //     style={{
        //       position: "absolute",
        //       right: 0,
        //       height: "100%",
        //       justifyContent: "center",
        //       paddingLeft: 20,
        //     }}
        //     onPress={() => {
        //       navigation.navigate("BotOrder", {
        //         copy_trade_id,
        //       });
        //     }}
        //   >
        //     <View
        //       style={{
        //         paddingHorizontal: 20,
        //         flexDirection: "row",
        //         paddingVertical: 6,
        //       }}
        //     >
        //       <svg.HistorySvg />
        //     </View>
        //   </TouchableOpacity>
        // }
      />
    );
  };

  const handleSubmit = (values) => {
    let followVal = values?.is_following === false ? "0" : "1"
    setFormData(values);

    if(copyTrade?.is_following == "1" && followVal == "0" && copyTrade?.bep?.pos_size != 0){
      setModal({ show: true, type: "confirmCloseRelatedPositions" });
    } else {
      setModal({ show: true, type: "confirm" });
    }    
  };

  const handleSave = () => {
    setModal({ show: false });
    updateCopyTrade({
      ...formData,
      copy_trade_id,
      // platform,
      is_following: formData?.is_following === false ? "0" : "1",
      enable_max_pnl: formData?.enable_max_pnl === false ? "0" : "1"
    })
    // .finally(() => {
    //   setModal({ show: false });
    // });
  };  

  return (
    <>
      <ApSafeAreaView>
        {renderHeader()}

        {initLoading ? (
          <ApLoader />
        ) : (
          <View style={{ paddingHorizontal: 20, paddingVertical: 20 }}>
            <ApRow>
              <ApText
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              >
                {selectedTrade?.label}
              </ApText>

              <ApText
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              >
                {selectedTrade?.description}
              </ApText>
            </ApRow>

            <View
              style={{
                backgroundColor: "#FCF8F6",
                padding: 10,
                marginBottom: 10,
                borderRadius: 10,
              }}
            >
              <ApText style={{ fontSize: 14 }}>
                <ApText size="sm">{selectedTrade?.description2}</ApText>
              </ApText>
            </View>

            <View style={{ marginBottom: 15 }}>
              <View style={{ flexDirection: "row" }}>
                <ApText
                  translateKey="Followers"
                  style={{ fontSize: 12, color: "#F37021" }}
                />
                <ApText style={{ fontSize: 12, color: "#F37021" }}>
                  {" : "}
                  {selectedTrade?.follower_count}/
                  {selectedTrade?.max_total_follower_count}
                </ApText>
              </View>

              <View style={{ flexDirection: "row" }}>
                <ApText
                  translateKey="FundSize"
                  style={{ fontSize: 12, color: "#F37021" }}
                />
                <ApText style={{ fontSize: 12, color: "#F37021" }}>
                  {" : "}
                  {selectedTrade?.total_init_fund}/
                  {selectedTrade?.max_total_follower_fund}
                </ApText>
              </View>

              <View style={{ flexDirection: "row" }}>
                <ApText
                  translateKey="CommissionPercentage"
                  style={{ fontSize: 12, color: "#F37021" }}
                />
                <ApText style={{ fontSize: 12, color: "#F37021" }}>
                  {" : "}
                  {selectedTrade?.profit_sharing_perc}%
                </ApText>
              </View>
            </View>

            <View
              style={{
                backgroundColor: "#FCF8F6",
                padding: 15,
                paddingVertical: 10,
                marginBottom: 15,
                borderRadius: 10,
              }}
            >
              <ApText
                translateKey="CopyTradeStatus"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />

              <ApRow style={{ marginBottom: 10 }}>
                <ApRow style={{ width: "48%" }}>
                  <ApText size="xs" translateKey="Orders" />
                  <ApText size="xs" color="#F37021" font="bold">
                    {copyTrade?.total_trades}
                  </ApText>
                </ApRow>

                <ApRow style={{ width: "48%" }}>
                  <ApText size="xs" translateKey="ProfitAndLoss" />
                  <ApText size="xs" color="#F37021" font="bold">
                    {copyTrade?.total_pnl}
                  </ApText>
                </ApRow>
              </ApRow>

              <ApRow>
                <ApRow style={{ width: "48%" }}>
                  <ApText size="xs" translateKey="30DP&L" />
                  <ApText size="xs" color="#F37021" font="bold">
                    {copyTrade?.total_pnl30d}
                  </ApText>
                </ApRow>

                <ApRow style={{ width: "48%" }}>
                  <ApText size="xs" translateKey="FollowedAt" />
                  <ApText size="xs" color="#F37021" font="bold">
                    {copyTrade?.following_at
                      ? moment(copyTrade?.following_at).format("YYYY-MM-DD")
                      : "-"}
                  </ApText>
                </ApRow>
              </ApRow>
            </View>

            <View
              style={{
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 20,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("CopyTradeActionLog", {
                    copy_trade_id
                  })
                }
                style={{
                  height: 32,
                  width: "48%",
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: "#F37021",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ApText
                  style={{ color: "#F37021", fontWeight: "500" }}
                  translateKey="Action"
                />
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("BotOrder", {
                    copy_trade_id,
                  })
                }
                style={{
                  height: 32,
                  width: "48%",
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: "#F37021",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ApText
                  style={{ color: "#F37021", fontWeight: "500" }}
                  translateKey="Order"
                />
              </TouchableOpacity>
            </View>

            <Formik
              enableReinitialize
              innerRef={formRef as any}
              validationSchema={FormSchema}
              initialValues={{
                agree: false,
                is_following: copyTrade?.is_following === "1",
                tradable_fund: copyTrade?.tradable_fund,
                leverage: copyTrade?.leverage,
                platform: ''+copyTrade?.platform,
                max_profit: copyTrade?.max_profit,
                max_loss: copyTrade?.max_loss,
                enable_max_pnl: copyTrade?.enable_max_pnl == 1 ? true : false
              }}
              onSubmit={(val) => handleSubmit(val)}
            >
              {(props: FormikProps<any>) => (
                <>
                  <ApText
                    translateKey="Settings"
                    style={{
                      fontSize: 14,
                      color: "#F37021",
                      fontWeight: "500",
                      paddingBottom: 10,
                    }}
                  />

                  <View
                    style={{
                      marginBottom: 20,
                      padding: 15,
                      paddingTop: 2,
                      backgroundColor: "#FCF8F6",
                      borderRadius: 10,
                    }}
                  >
                    <ApToggleInput
                      onToggle={(val) => {}}
                      labelTranslateKey="Follow"
                      defaultValue={copyTrade?.is_following == 1 ? true : false}
                      label="Follow"
                      name="is_following"
                      labelStyle={style.inputLabel}
                      labelIcon={
                        <TouchableOpacity
                          onPress={() => setModal({ show: true, type: "hint", hintKey:"FollowCopyTradeHint" })}
                        >
                          <InfoSvg strokeColor="#F37021" />
                        </TouchableOpacity>
                      }
                    />

                    <ApDropdown
                      label="Platform"
                      labelIcon={
                        <TouchableOpacity
                          onPress={() => setModal({ show: true, type: "hint", hintKey:"PlatformHint" })}
                        >
                          <InfoSvg strokeColor="#F37021" />
                        </TouchableOpacity>
                      }
                      name="platform"
                      placeholder="Platform"
                      labelTranslateKey="Platform"
                      items={TRADING_PLATFORMS_OPTIONS}
                    />

                    <ApTextInput
                      border
                      width={130}
                      height={25}
                      labelTranslateKey="FundSize"
                      label="Fund Size"
                      hintTranslateKeys={[
                        `${t("FundSizeHint")} ${
                          selectedTrade?.min_follower_fund || '0'
                        }`, (copyTrade?.is_dynamic_fund == "1" && "CopyTradeFundSizeHint")
                      ]}
                      labelStyle={style.inputLabel}
                      name="tradable_fund"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      rightIcon={
                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 8,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          USDT
                        </ApText>
                      }
                    />

                    {/* {market === "1" ? (
                      <></>
                    ) : ( */}
                    <ApTextInput
                      labelIcon={
                        <TouchableOpacity
                          onPress={() => setModal({ show: true, type: "hint", hintKey:"LeverageHint" })}
                        >
                          <InfoSvg strokeColor="#F37021" />
                        </TouchableOpacity>
                      }
                      rightIcon={
                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 10,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          X
                        </ApText>
                      }
                      border
                      width={130}
                      height={25}
                      label="Leverage"
                      labelTranslateKey="Leverage"
                      labelStyle={style.inputLabel}
                      name="leverage"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                    {/* )} */}

                    <ApToggleInput
                      onToggle={(val) => {}}
                      labelTranslateKey="EnableMaxProfitOrLoss"
                      defaultValue={copyTrade?.enable_max_pnl == 1 ? true : false}
                      label="Enable Max Profit / Loss"
                      noBorder={!props?.values?.enable_max_pnl ? true : false}
                      name="enable_max_pnl"
                      labelStyle={style.inputLabel}
                    /> 

                    {props?.values?.enable_max_pnl && (
                      <>
                        <ApTextInput
                          border
                          width={130}
                          height={25}
                          labelTranslateKey="MaxProfit"
                          label="Max Profit"
                          labelStyle={style.inputLabel}
                          name="max_profit"
                          inputStyle={{
                            ...style.textInput,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          rightIcon={
                            <ApText
                              style={{
                                backgroundColor: "#F37021",
                                color: "#FFF",
                                fontSize: 10,
                                paddingHorizontal: 8,
                                paddingVertical: 0.5,
                                borderTopRightRadius: 6,
                                borderBottomRightRadius: 6,
                              }}
                            >
                              USDT
                            </ApText>
                          }
                        />

                        <ApTextInput
                          border
                          width={130}
                          height={25}
                          labelTranslateKey="MaxLoss"
                          label="Max Loss"
                          labelStyle={style.inputLabel}
                          name="max_loss"
                          containerStyle={{
                            borderBottomColor: "#FCF8F6",
                            paddingBottom: 0,
                          }}
                          inputStyle={{
                            ...style.textInput,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          rightIcon={
                            <ApText
                              style={{
                                backgroundColor: "#F37021",
                                color: "#FFF",
                                fontSize: 10,
                                paddingHorizontal: 8,
                                paddingVertical: 0.5,
                                borderTopRightRadius: 6,
                                borderBottomRightRadius: 6,
                              }}
                            >
                              USDT
                            </ApText>
                          }
                        />
                      </>                      
                    )}
                  </View>

                  <ApCheckInputAlt
                    noBorder
                    name="agree"
                    labelTranslateKey="BotSettingTerms"
                    labelStyle={{
                      fontSize: 12,
                      color: "#F37021",
                      marginLeft: "10px",
                    }}
                  />

                  <View style={{ width: "100%" }}>
                    <ApButton
                      round="lg"
                      loading={loading}
                      onPress={() => props.handleSubmit()}
                      labelTranslateKey={"Confirm"}
                    />
                  </View>
                </>
              )}
            </Formik>
          </View>
        )}
      </ApSafeAreaView>

      {modal?.show && (
        <ApModalAlt
          fallbackRoute={`CopyTradeDetails?bp_id=${copy_trade_id}`}
          transparent={true}
          visible={modal?.show}
        >
          {modal?.type === "hint" && (
            <>
              <TouchableOpacity
                onPress={() => {
                  setModal({ show: false });
                }}
                style={{
                  alignItems: "flex-end",
                  padding: 15,
                  paddingBottom: 0,
                }}
              >
                <svg.closeSvg />
              </TouchableOpacity>

              <View
                style={{
                  width: "100%",
                  paddingTop: 0,
                  paddingVertical: 20,
                  paddingHorizontal: 20,
                }}
              >
                <ApText
                  style={{ marginTop: 5, marginLeft: 3, fontSize: 14 }}
                  // translateKey="LeverageHint"
                  translateKey={modal.hintKey}
                />
              </View>
            </>
          )}

          {modal.type === "confirm" && (
            <>
              <TouchableOpacity
                onPress={() => {
                  setModal({ show: false });
                }}
                style={{
                  alignItems: "flex-end",
                  padding: 15,
                  paddingBottom: 0,
                }}
              >
                <svg.closeSvg />
              </TouchableOpacity>

              <View
                style={{
                  paddingTop: 0,
                  paddingVertical: 20,
                  alignItems: "center",
                  paddingHorizontal: 20,
                }}
              >
                <ApText
                  size="lg"
                  style={{
                    marginTop: -5,
                    color: "#F37021",
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                  translateKey="Notice"
                >
                  Notice
                </ApText>
                <ApText
                  size="sm"
                  style={{ textAlign: "center" }}
                  translateKey="ConfirmCopyTradeNote"
                ></ApText>

                <View
                  style={{
                    width: "100%",
                    marginTop: 30,
                    flexDirection: "row",
                    //paddingVertical: 20,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      marginRight: 10,
                      borderWidth: 1,
                      borderColor: "#F37021",
                      width: "47%",
                      height: 38,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      setModal({ show: false });
                    }}
                  >
                    <ApText
                      style={{ color: "#F37021", fontWeight: "500" }}
                      translateKey="Cancel"
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      backgroundColor: "#F37021",
                      width: "47%",
                      height: 38,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      handleSave();
                    }}
                  >
                    <ApText
                      style={{ color: theme.COLORS.white, fontWeight: "500" }}
                      translateKey="Confirm"
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </>
          )}

          {modal.type === "confirmCloseRelatedPositions" && (
            <>
              <TouchableOpacity
                onPress={() => {
                  setModal({ show: false });
                }}
                style={{
                  alignItems: "flex-end",
                  padding: 15,
                  paddingBottom: 0,
                }}
              >
                <svg.closeSvg />
              </TouchableOpacity>

              <View
                style={{
                  paddingTop: 0,
                  paddingVertical: 20,
                  alignItems: "center",
                  paddingHorizontal: 20,
                }}
              >
                <ApText
                  size="lg"
                  style={{
                    marginTop: -5,
                    color: "#F37021",
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                  translateKey="Notice"
                >
                  Notice
                </ApText>
                <ApText
                  size="sm"
                  style={{ textAlign: "center" }}
                  translateKey="ConfirmCloseRelatedPositions"
                ></ApText>

                <View
                  style={{
                    width: "100%",
                    marginTop: 30,
                    flexDirection: "row",
                    //paddingVertical: 20,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      marginRight: 10,
                      borderWidth: 1,
                      borderColor: "#F37021",
                      width: "47%",
                      height: 38,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      setModal({ show: false });
                    }}
                  >
                    <ApText
                      style={{ color: "#F37021", fontWeight: "500" }}
                      translateKey="Cancel"
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      backgroundColor: "#F37021",
                      width: "47%",
                      height: 38,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      handleSave();
                    }}
                  >
                    <ApText
                      style={{ color: theme.COLORS.white, fontWeight: "500" }}
                      translateKey="Confirm"
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </>
          )}
        </ApModalAlt>
      )}
    </>
  );
};

export default CopyTradeDetails;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: "20%",
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    borderRadius: 6,
  },
});
