import React, { useState } from "react";
import { ICopyTrade, ICopyTradePayload } from "../models/copy.interface";
import { ApiService } from "../services/ApiService";
import { ApErrorToast, ApSuccessToast } from "../services";

interface IStrategyState {
  loading: boolean;
  initLoading: boolean;
  error: any;
  selectedTrade: ICopyTrade;
  copyTrades: ICopyTrade[];
  copyTrade: ICopyTrade;
  actionLogs: any[]
  updateCopyTrade: (trade: ICopyTradePayload) => Promise<any>;
  setSelectedTrade: (trade: ICopyTrade) => void;
  getCopyTrades: () => Promise<ICopyTrade[]>;
  getCopyTradeDetail: (id: string) => Promise<ICopyTrade>;
  getActionLogs: (copyTradeId: string, providerId: string) => Promise<any>
}

const CopyContext = React.createContext<IStrategyState>({
  initLoading: true,
  loading: false,
  error: "",
  selectedTrade: null,
  copyTrade: null,
  copyTrades: [],
  actionLogs: [],
  getActionLogs(copyTradeId, providerId) {
    return null;
  },
  updateCopyTrade(trade) {},
  setSelectedTrade(trade) {},
  getCopyTrades() {
    return null;
  },
  getCopyTradeDetail(id) {
    return null;
  },
} as IStrategyState);

const useCopyState = () => {
  const context = React.useContext(CopyContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }

  return context;
};

interface IProps {
  children: React.ReactNode;
}

const CopyContextProvider: React.FC<IProps> = ({ children }) => {
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const [copyTrades, setCopyTrades] = useState<ICopyTrade[]>([]);
  const [copyTrade, setCopyTrade] = useState<ICopyTrade>();
  const [selectedTrade, setSelectedTrade] = useState<ICopyTrade>();
  const [actionLogs, setActionLogs] = useState<any[]>([]);

  const getCopyTrades = async (): Promise<ICopyTrade[]> => {
    setLoading(true);
    return await ApiService.get("trading/getCopyTrades")
      .then((rs) => {
        setCopyTrades(rs?.data?.data);
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCopyTradeDetail = async (id: string): Promise<ICopyTrade> => {
    setInitLoading(true);
    return await ApiService.get(
      `trading/getCopyTradeFollowDetails?copy_trade_id=${id}`
    )
    .then((rs) => {
      setCopyTrade(rs?.data?.data);
      return rs?.data?.data;
    })
    .finally(() => {
      setInitLoading(false);
    });
  };

  const updateCopyTrade = async (payload: ICopyTradePayload): Promise<any> => {
    setLoading(true);
    return await ApiService.post(
      `trading/updateCopyTradeFollowDetails`,
      payload
    )
      .then(async (rs) => {
        ApSuccessToast("", rs?.data?.msg);
        const trade = await getCopyTradeDetail(payload.copy_trade_id);
        setCopyTrades(
          copyTrades?.map((c) =>
            c.id === payload.copy_trade_id ? { ...c, ...trade } : c
          )
        );
        return rs?.data;
      })
      .catch((err) => {
        ApErrorToast("", err?.message || err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getActionLogs = async (copyTradeId: string, providerId: string): Promise<any> => {
    setInitLoading(true);
    let url = providerId ? `metatrader/getSubscriptionActionLogs?provider_id=${providerId}`
    : `trading/getCopyTradeFollowActionLogs?copy_trade_id=${copyTradeId}`
    return await ApiService.get(url)
    .then((rs) => {
      setActionLogs(rs.data.data);
      return rs?.data?.data;
    })
    .finally(() => {
      setInitLoading(false);
    });
  };

  return (
    <CopyContext.Provider
      value={{
        actionLogs,
        getActionLogs,
        initLoading,
        loading,
        error,
        copyTrade,
        copyTrades,
        getCopyTrades,
        selectedTrade,
        updateCopyTrade,
        setSelectedTrade,
        getCopyTradeDetail,
      }}
    >
      {children}
    </CopyContext.Provider>
  );
};

export { CopyContextProvider, useCopyState };
